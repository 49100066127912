<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('document_type_setting.document_type_setting') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('document_type_setting.document_type') }}<span class="text-danger">*</span></label>
                <select name="document_type" id="document_type" v-model="data.doc_type" class="custom-select" :class="validation && validation.doc_type ? 'is-invalid' : ''">
                  <option v-for="row in document_type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.doc_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.doc_type[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('document_type_setting.document_slug') }}<span class="text-danger">*</span></label>
                <input :value="data.slug" disabled class="form-control" :class="validation && validation.slug ? 'is-invalid' : ''">
                <span v-if="validation && validation.slug" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.slug[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('document_type_setting.document_name') }}<span class="text-danger">*</span></label>
                <input v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''">
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('document_type_setting.status_accounting') }}<span class="text-danger">*</span></label>
                <select name="client_layout" id="client_layout" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('document_type_setting.columns') }}<span class="text-danger">*</span></label>
                <multiselect v-model="data.columns_doc"
                             :placeholder="$t('document_type_setting.columns')"
                             :options="columns_doc_list"
                             :multiple="true"
                             :taggable="true"
                             :class="validation && validation.columns_doc ? 'is-invalid' : ''"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.columns_doc" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.columns_doc[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <b-card no-body class="mt-5">
        <b-card-header header-tab="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
            {{ $t('document_type_setting.description_columns')}}
          </b-button>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body class="row">
              <table class="table">
                <thead>
                <tr>
                  <th>{{ $t('document_type_setting.column') }}</th>
                  <th>{{ $t('document_type_setting.description_ar') }}</th>
                  <th>{{ $t('document_type_setting.description_en') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in details_list" :key="'details_'+index">
                  <td>{{ row.key }}</td>
                  <td>{{ row.description_ar }}</td>
                  <td>{{ row.description_en }}</td>
                </tr>
                </tbody>
              </table>
          </b-card-body>
        </b-collapse>
      </b-card>
      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>


  </div>
  <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-document-type-setting",
  data() {
    return {
      mainRoute: 'accounting/document_type_settings',
      mainRouteDependency: 'base/dependency',
      mainRouteByDocType: 'base/dependency/data_doc',

      document_type_list: [],
      status_list: [],
      columns_doc_list: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,

      data: {
        doc_type: null,
        name: null,
        slug: null,
        status: null,
        columns_doc: null,
      },
      isEditing: false,
      validation: null,
      details_list: [],

    };
  },

  watch: {
    'data.doc_type': function (val) {
      if (val) {
        let _doc = this.document_type_list.find((row) => row.id == val);
        if (_doc) {
          this.data.slug = _doc.slug;
        }
        let promise = this.getDataByDocType();
        Promise.all([promise]).then(() => {
          this.getStatusList(val);
          this.getColumnsDocList(val);
          this.getDetailsListColumnsDoc(val);

        });
      } else {
        this.status_list = [];
        this.columns_doc_list = [];
      }
    }
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/document-type-setting');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/document-type-setting');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.doc_type = response.data.data.doc_type;
        this.data.name = response.data.data.name;
        this.data.slug = response.data.data.slug;
        this.data.status = response.data.data.status;
        this.data.columns_doc = response.data.data.columns_doc;
      });
    },
    async getDataByDocType() {
      this.validation = null;
      await ApiService.get(this.mainRouteByDocType + '/' + this.data.doc_type).then((response) => {
        if (response.data.data) {
          this.isEditing = true;
          this.idEdit = response.data.data.id;
          this.data.doc_type = response.data.data.doc_type;
          this.data.name = response.data.data.name;
          this.data.slug = response.data.data.slug;
          this.data.status = response.data.data.status;
          this.data.columns_doc = response.data.data.columns_doc;
        } else {
          this.isEditing = false;
          this.idEdit = null;
          this.data.name = null;
          //this.data.slug = null;
          this.data.status = null;
          this.data.columns_doc = null;
        }
      });
    },

    getDocumentTypeList() {
      ApiService.get(this.mainRouteDependency + "/doc_type_list").then((response) => {
        this.document_type_list = response.data.data;
      });
    },

    getStatusList(id) {
      ApiService.get(this.mainRouteDependency + "/status_by_doc_type/" + id).then((response) => {
        this.status_list = response.data.data;
      });
    },

    getColumnsDocList(id) {
      ApiService.get(this.mainRouteDependency + "/columns_doc/" + id).then((response) => {
        this.columns_doc_list = response.data.data;
      });
    },

    getDetailsListColumnsDoc(id) {
      ApiService.get(this.mainRouteDependency + "/details_columns_doc/" + id).then((response) => {
        this.details_list = response.data.data;
      });
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.document_type_setting"), route: '/accounting/document-type-setting'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getDocumentTypeList();

    if (this.idEdit) {
      this.getData();
    }

  },
};
</script>


